import { ChainId } from '@my/sdk';

export const dAppStakingcontract = {
  [ChainId.BSC_TESTNET]: '0x756f158A2C02246Bf00bbdB051729804F2efd9c7',
  [ChainId.BSC_MAINNET]: '0xB686E5a005005A6e64817C3a08212cFDAE5f619b',
  [ChainId.SDN_MAINNET]: '0x756f158A2C02246Bf00bbdB051729804F2efd9c7',
  [ChainId.SDN_TESTNET]: '0xB686E5a005005A6e64817C3a08212cFDAE5f619b',
  [ChainId.ASTR_MAINNET]: '0x56823CE52ab02e247a78ceC08715FEcE228ab682', // ok
  [ChainId.ASTR_TESTNET]: '0x50CE77Ed745374980aE8366424e79D08bD1BB37B', // ok
};

export const dAppStakingMaincontract = {
  [ChainId.BSC_TESTNET]: '0x0000000000000000000000000000000000005001',
  [ChainId.BSC_MAINNET]: '0x0000000000000000000000000000000000005001',
  [ChainId.SDN_MAINNET]: '0x0000000000000000000000000000000000005001',
  [ChainId.SDN_TESTNET]: '0x0000000000000000000000000000000000005001',
  [ChainId.ASTR_MAINNET]: '0x0000000000000000000000000000000000005001',
  [ChainId.ASTR_TESTNET]: '0x0000000000000000000000000000000000005001', // ok
};

export const RATIO_PRECISION = 100000000; //precision: 0.00000001
